import React, { useEffect, useState } from "react";
import { createClient, SubscribePayload } from "graphql-sse";
import { useNavigate } from "react-router-dom";

import QRCode from "react-qr-code";
import axios from "axios";
function List({ ob = false }) {
  const [videos, setVideos] = useState(null);
  const [currentObVideo, setCurrentObVideo] = useState(null);
  const [currentLoopVideo, setCurrentLoopVideo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const idRbe = localStorage.getItem("rbev-eventId");
    if (!idRbe) return () => {};

    let wssUrl = `wss://robot-events.soolutions.net/videos/${idRbe}`;

    let parser = (event) => {
      try {
        let body = JSON.parse(event.data);
        // console.log(body);
        if (body?.videos) setVideos(body.videos);
        if (body?.obVideo) setCurrentObVideo(body.obVideo);
        if (body?.loopVideo) setCurrentLoopVideo(body.loopVideo);
        if (body?.reconnect) navigate("/login");
      } catch (e) {}
    };

    function onClose() {
      setTimeout(() => {
        window.ws = new WebSocket(wssUrl, localStorage.getItem("rbev-token"));
        window.ws.onmessage = parser;
        window.ws.onclose = onClose;
      }, Math.round(Math.random() * 2000));
    }
    window.ws = new WebSocket(wssUrl, localStorage.getItem("rbev-token"));
    window.ws.onmessage = parser;
    window.ws.onclose = onClose;

    return () => {
      if (window.ws) {
        try {
          window.ws.close();
        } catch (e) {
          console.log(e.message || e);
        }
      }
    };
  }, []);

  if (!videos) return <div>Loading...</div>;
  return (
    <div>
      <div className="video-preview-container">
        {videos.map((video, key) => {
          const parts = video.video.split("/");
          const clipNumber = parts[parts.length - 1].split(".")[0];
          return (
            <div
              className={
                "video-preview-box" +
                ((ob && video?._id === currentObVideo?._id) ||
                (!ob && currentLoopVideo?._id === video?._id)
                  ? " active"
                  : "") +
                (ob ? " ob" : "")
              }
              key={video._id}
            >
              <div className="video-player-card">
                <img src={video.img} alt="video preview" />
                {ob ? null : (
                  <div draggable="true" className="video-preview-qr">
                    <QRCode
                      className="svg-qrcode"
                      value={"https://app.robotevents.it/video/" + video._id}
                      viewBox="4.5 1.5 256 256"
                    />
                  </div>
                )}
                <span className="clip-number">{clipNumber}</span>
                <button
                  id={video._id}
                  className="action-button"
                  onClick={() => {
                    axios({
                      method: "post",
                      url:
                        "https://robot-events.soolutions.net/cast-video" +
                        (ob ? "-ob" : "-loop"),
                      headers: {
                        "Content-Type": "application/json",
                      },
                      data: JSON.stringify({
                        id: video._id,
                      }),
                    })
                      .then(function (response) {
                        console.log(JSON.stringify(response.data));
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M447.8,64H64c-23.6,0-42.7,19.1-42.7,42.7v63.9H64v-63.9h383.8v298.6H298.6V448H448c23.6,0,42.7-19.1,42.7-42.7V106.7 C490.7,83.1,471.4,64,447.8,64z M21.3,383.6L21.3,383.6l0,63.9h63.9C85.2,412.2,56.6,383.6,21.3,383.6L21.3,383.6z M21.3,298.6V341 c58.9,0,106.6,48.1,106.6,107h42.7C170.7,365.6,103.7,298.7,21.3,298.6z M213.4,448h42.7c-0.5-129.5-105.3-234.3-234.8-234.6l0,42.4 C127.3,255.6,213.3,342,213.4,448z" />
                  </svg>
                </button>

                <button
                  id={video._id + "-download"}
                  className="action-button btn-left"
                  onClick={() => {
                    if (ob && video.videoClean) {
                      const link = document.createElement("a");
                      link.href = video.videoClean;
                      link.target = "_blank";
                      link.click();
                    } else {
                      fetch(video.video)
                        .then(function (response) {
                          return response.blob();
                        })
                        .then(function (blob) {
                          var file = new File([blob], "RobotEvents.mp4", {
                            type: "video/mp4",
                          });
                          var filesArray = [file];

                          navigator.share({
                            files: filesArray,
                          });
                        })
                        .catch(console.error);
                    }
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" />
                  </svg>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default List;
