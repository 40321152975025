import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Login() {
  const [username, setusername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <div className="login-box">
        <input
          type="text"
          value={username}
          onChange={(e) => setusername(e.target.value)}
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div
          className="login-btn"
          onClick={() => {
            axios({
              method: "post",
              url: "https://robot-events.soolutions.net/auth",
              headers: {
                "Content-Type": "application/json",
              },
              data: JSON.stringify({
                username,
                password,
              }),
            })
              .then(function (response) {
                if (response.data?.token)
                  localStorage.setItem("rbev-token", response.data.token);
                  localStorage.setItem("rbev-eventId", username);
                navigate("/list");
              })
              .catch(function (error) {
                console.log(error);
              });
          }}
        >
          LOGIN
        </div>
      </div>
    </div>
  );
}

export default Login;
