import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";

function Player() {
  const [video, setVideo] = useState(null);
  useEffect(() => {
    let wssUrl = `wss://robot-events.soolutions.net/cast-video`;

    let parser = (event) => {
      try {
        let body = JSON.parse(event.data);
        console.log(body);
        if (body?.video) setVideo(body.video);
      } catch (e) {}
    };

    function onClose() {
      setTimeout(() => {
        window.ws = new WebSocket(wssUrl);
        window.ws.onmessage = parser;
        window.ws.onclose = onClose;
      }, Math.round(Math.random() * 2000));
    }

    window.ws = new WebSocket(wssUrl);
    window.ws.onmessage = parser;
    window.ws.onclose = onClose;

    return () => {
      if (window.ws) {
        try {
          window.ws.close();
        } catch (e) {
          console.log(e.message || e);
        }
      }
    };
  }, []);

  if (!video) return <div>waiting...</div>;
  return (
    <div className="ov-hidden">
      <video
        src={video.video}
        width="100%"
        autoPlay
        loop
        muted
        playsInline
      ></video>

      {video._id && (
        <div className="qrcode">
          <QRCode value={"https://app.robotevents.it/video/" + video._id} />
        </div>
      )}
    </div>
  );
}

export default Player;
