import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import logo from './logo.svg';
import logo from "../LogoCucinelli.png";

function isAppleMobileDeviceFinder() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (
    /iPad|iPhone|iPod|iOs|iOS/.test(userAgent) && !/Android/.test(userAgent)
  );
}

function Video() {
  const [video, setVideo] = useState(null);
  const [mailsent, setMailsent] = useState(false);
  const [mail, setMail] = useState("");
  const [gdpr, setGdpr] = useState(false);
  const [minified, setMinified] = useState(true);
  const videoEl = useRef(null);
  let params = useParams();

  let id = params.id;

  // se true non chiediamo la mail
  let isLegacy = true;

  let shareTitle = "SHARE";
  let downloadTitle = "DOWNLOAD";

  let isAppleMobileDevice = isAppleMobileDeviceFinder();
  let canShare = navigator.share ? true : false;

  if (isAppleMobileDevice) {
    shareTitle = "DOWNLOAD";
    downloadTitle = "";
  }
  if (!canShare) {
    shareTitle = "";
  }

  if (id.includes("old")) {
    id = id.split("old")[0];
    isLegacy = true;
  }

  useEffect(() => {
    var config = {
      method: "get",
      url: "https://robot-events.soolutions.net/videos/" + id,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        if (!response?.data?.video) return console.warn("no video found!");
        setVideo(response.data);
        fetch(response.data.video)
          .then(function (response) {
            return response.blob();
          })
          .then(function (blob) {
            console.log("preloaded!");
          })
          .catch(console.error);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [id]);

  useEffect(() => {
    if (mailsent || isLegacy) {
      setTimeout(() => {
        try {
          videoEl.current.play();
        } catch {}
      }, 1600);
    }
  }, [mailsent, isLegacy]);

  if (!video?.video) return <div className="instructions">Loading...</div>;

  if (!isLegacy && !mailsent)
    return (
      <div className="registration">
        <div className="navbar">
          {false ? (
            <a
              target={"_blank"}
              rel="noreferrer"
              href="https://tkio.link/REM8C"
            >
              <img src={logo} className="App-logo" alt="logo" />
            </a>
          ) : (
            <img src={logo} className="App-logo" alt="logo" />
          )}
        </div>
        <div className="registrati-box">
          <div className="registrati-title">
            Ciao! Inserisci la tua mail <br />
            per scaricare il video
          </div>
          <div className="registrati-form">
            <input
              className="registrati-cta"
              name="email"
              type={"email"}
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              required
              placeholder="your@mail.com"
            />
            <div className="checkbox">
              <input
                value={gdpr}
                onChange={(e) => setGdpr(e.target.value)}
                className="registrati-gdpr"
                name="gdpr"
                type={"checkbox"}
                required
              />
              <label htmlFor="gdpr">
                GDPR Accetto di iscrivermi alla newsletter e acconsento al{" "}
                <a
                  href="https://www.tokiostudio.it/privacy"
                  target="_blank"
                  rel="noreferrer"
                >
                  trattamento dei dati
                </a>
              </label>
            </div>
            <button
              onClick={function () {
                if (
                  mail &&
                  gdpr &&
                  mail.length > 5 &&
                  mail.includes("@") &&
                  mail.includes(".")
                )
                  axios
                    .post(
                      "https://hook.eu1.make.com/o5t46ggdj3sua9mfkjzc13sy8reuz8cl",
                      {
                        mail,
                        gdpr,
                        video: "https://app.robotevents.it/video/" + params.id,
                      }
                    )
                    .then(function (response) {
                      setMailsent(true);
                    })
                    .catch(function (error) {
                      console.log(error);
                    });
              }}
            >
              CONTINUA
            </button>
          </div>
        </div>
      </div>
    );
  return (
    <div className="page-video-player-container">
      <div className="navbar">
        {false ? (
          <a target={"_blank"} rel="noreferrer" href="https://tkio.link/REM8C">
            <img src={logo} className="App-logo" alt="logo" />
          </a>
        ) : (
          <img src={logo} className="App-logo" alt="logo" />
        )}
      </div>
      <div className="video-container">
        <div
          className="video-player"
          onClick={() => {
            setMinified(false);
          }}
        >
          <video
            ref={videoEl}
            datatype="video/mp4"
            src={video.video + "?ext=.mp4"}
            width="80%"
            autoPlay
            loop
            muted={minified}
            playsInline
            controls={!minified}
          >
            Your browser does not support the video tag.
          </video>
        </div>
        {shareTitle ? (
          <div className="video-download">
            <div
              className="video-share"
              onClick={() => {
                fetch(video.video)
                  .then(function (response) {
                    return response.blob();
                  })
                  .then(function (blob) {
                    var file = new File([blob], "RobotEvents.mp4", {
                      type: "video/mp4",
                    });
                    var filesArray = [file];

                    navigator.share({
                      files: filesArray,
                    });
                  })
                  .catch(console.error);
              }}
            >
              {shareTitle}
            </div>
          </div>
        ) : null}
        {downloadTitle ? (
          <div className="video-download">
            <a
              title="video robot events"
              download="RobotEvents.mp4"
              datatype="mp4"
              href={video.video}
            >
              Download
            </a>
          </div>
        ) : null}

        <div className="instructions">
          {/* <p className="instruction-paragraph">
            Scarica, pubblica e taggaci! <br />
            <a href="https://www.instagram.com/tokiostudio.it/" target="_blank" rel="noreferrer">
              &nbsp;@tokiostudio.it
            </a>{" "}
            <a href="https://www.instagram.com/wearemarketers/" target="_blank" rel="noreferrer">
              &nbsp;@wearemarketers
            </a>
          </p> */}

          {/* <p className="instruction-paragraph">Scarica e pubblica!</p> */}
        </div>
      </div>
    </div>
  );
}

export default Video;
