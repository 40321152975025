import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import QRCode from "react-qr-code";

function QRCodePageTrigger( {wssUrlBase} ) {
  const [video, setVideo] = useState(null);
  const {id} = useParams();
  const wssUrl = wssUrlBase + id;
  useEffect(() => {

    let parser = (event) => {
      try {
        let body = JSON.parse(event.data);
        console.log(body);
        if (body?.video) setVideo(body.video);
      } catch (e) {}
    };

    function onClose() {
      setTimeout(() => {
        window.ws = new WebSocket(wssUrl);
        window.ws.onmessage = parser;
        window.ws.onclose = onClose;
      }, Math.round(Math.random() * 2000));
    }

    window.ws = new WebSocket(wssUrl);
    window.ws.onmessage = parser;
    window.ws.onclose = onClose;

    document.body.classList.add("bg-transparent");

    return () => {
      document.body.classList.remove("bg-transparent");
      if (window.ws) {
        try {
          window.ws.onclose = ()=>{};
          window.ws.close();
        } catch (e) {
          console.log(e.message || e);
        }
      }
    };
  }, [id, wssUrlBase]);

  if (!video) return <div>waiting...</div>;
  return (
    <div className="bg-transparent">
      {video._id && (
        <div className="qrcode">
          <QRCode value={"https://app.robotevents.it/video/" + video._id} />
        </div>
      )}
    </div>
  );
}

export default QRCodePageTrigger;
