import React from "react";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";

import List from "./pages/List";
import Login from "./pages/Login";
import Player from "./pages/Player";
import Video from "./pages/Video";

import "./App.css";
import PlayerTrigger from "./pages/PlayerTrigger";

// import logo from './logo.svg';
import logo from "./LogoCucinelli.png";
import QRCodePage from "./pages/QRCodePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <div className="navbar">
          <a target={"_blank"} href="https://tkio.link/REM8C" rel="noreferrer">
            <img src={logo} className="App-logo" alt="logo" />
          </a>
        </div>
        <div className="sponsored-by">
          <h5>Thank you for joining us!</h5>
          <p>If you want to know more, hop over to our website</p>
          <a href="https://tkio.link/REM8C">MOVIECHROME.COM</a>
          <br />
          <Link to="/login" style={{ backgroundColor: "black" }}>
            STAFF
          </Link>
        </div>
      </div>
    ),
  },
  {
    path: "list",
    element: <List />,
  },
  
  {
    path: "list-ob",
    element: <List ob={true}/>,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "live-player",
    element: <Player />,
  },
  {
    path: "cast-player",
    element: <PlayerTrigger />,
  },
  {
    path: "video/:id",
    element: <Video />,
  },
  {
    path: "live-qrcode/:id",
    element: <QRCodePage wssUrlBase={`wss://robot-events.soolutions.net/live-video/`}/>,
  },
  {
    path: "cast-qrcode/:id",
    element: <QRCodePage wssUrlBase={`wss://robot-events.soolutions.net/cast-video/`}/>,
  },
  {
    path: "ob-qrcode/:id",
    element: <QRCodePage wssUrlBase={`wss://robot-events.soolutions.net/ob-video/`}/>,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
